
import { eoRendererMixin, metaDataMixin, heroMixin } from '~/mixins'

export default {
  mixins: [
    eoRendererMixin,
    metaDataMixin,
    heroMixin,
  ],
  async asyncData ({ $api, $bugsnag, $config, $log, error }) {
    const { data: { items = [] } = {}, ...request } = await $api.pages.getOneBySlug('home', {
      domain: $config.preprEnvironmentDomain,
    })

    // check publication if exists and is complete
    if (!items[0]?.id) {
      $log.error(`Couldn't create the homepage of stichting.metterdaad.nl.`)
      $bugsnag.notify(new Error(`500: Cannot create Stichting Metterdaad homepage`), (e) => {
        e.severity = 'error'
        e.context = 'Homepage Stichting Metterdaad'
        e.addMetadata('Request info', request)
      })

      return error({ statusCode: 404, message: 'Oeps! De pagina is niet gevonden. ' })
    }
    return items[0]
  },
  data () {
    return {
      id: null,
      title: null,
      intro: null,
      heroUrl: null,
      content: null,
      seo: null,
      slug: null,
      social: null,
      featuredVideo: null,
    }
  },
}
